*,
*:before,
*:after {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    -ms-box-sizing: border-box;
    box-sizing: border-box;
}

body,
html {
    margin: 0;
    padding: 0;
}

figure {
    margin: 0;
}

ul,
li {
    margin: 0;
    padding: 0;
}

button {
    margin: 0;
    padding: 0;
    border: 0;
    padding: 0;
    margin: 0;
    background: transparent;
    font-family: inherit;
    color: inherit;
    cursor: pointer;
}

p {
    padding: 0;
    margin: 0;
}

$sm: 540;
$md: 720;
$lg: 960;
$xl: 1140;
@mixin MQ($sizeMin, $sizeMax) {
    @if $sizeMin !=0 and $sizeMax !=0 {
        @media screen and (min-width: $sizeMin + 'px') and (max-width: $sizeMax + 'px') {
            @content;
        }
    } @else if $sizeMin !=0 {
        @media screen and (min-width: $sizeMin + 'px') {
            @content;
        }
    } @else if $sizeMax !=0 {
        @media screen and (max-width: $sizeMax + 'px') {
            @content;
        }
    }
}

$main: #fff6f7;
$mainHover: #5d4679;
$subMain: #9b26af;
$subMainHover: #a23db3;

$backgroundPrimary: #fff6f7;
$backgroundSecundary: rgba(255, 246, 247, 0.1);

// @font-face {
//     font-family: 'OpenSansBold';
//     src: url('../font/OpenSans-Bold.eot');
//     src: url('../font/OpenSans-Bold.eot?#iefix') format('embedded-opentype'),
//         url('../font/OpenSans-Bold.woff2') format('woff2'),
//         url('../font/OpenSans-Bold.woff') format('woff'),
//         url('../font/OpenSans-Bold.ttf') format('truetype'),
//         url('../font/OpenSans-Bold.svg') format('svg');
//     font-weight: normal;
//     font-style: normal;
// }
// @font-face {
//     font-family: 'FontAwesome';
//     src: url('../lib/fontawesome/webfonts/fa-regular-400.eot');
//     src: url('../lib/fontawesome/webfonts/fa-regular-400.eot?#iefix') format('embedded-opentype'),
//         url('../lib/fontawesome/webfonts/fa-regular-400.woff2') format('woff2'),
//         url('../lib/fontawesome/webfonts/fa-regular-400.woff') format('woff'),
//         url('../lib/fontawesome/webfonts/fa-regular-400.ttf') format('truetype'),
//         url('../lib/fontawesome/webfonts/fa-regular-400.svg') format('svg');
//     font-weight: normal;
//     font-style: normal;
// }

body,
html {
    overflow-x: hidden;
    min-height: 100%;
}

body {
    font-family: Verdana, Geneva, sans-serif;
    // line-height: 0px;
    font-size: 16px;
    color: #555;
    background: $backgroundPrimary;
}

body,
input {
    font-weight: normal;
    font-style: normal;
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased;
}

::selection {
    background: #eb9fa4;
    color: #fff;
    text-shadow: none;
}

div {
    /*border: 1px solid yellow; temp: just to develop*/
}

// h1,h2,h3,h4,h5,h6,p {
//    font-family: inherit;
//    font-size: inherit;
// }
#root {
    background: $backgroundSecundary;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    min-height: 100vh;
}

header,
main {
    max-width: 1440px;
    margin: auto;
}

main {
    // flex: 1;
    flex-direction: column;
    display: flex;
    width: 100%;
    z-index: 1;
    position: relative;

    section {
        width: 90%;
        margin: auto;
    }
    nav {
        width: 20%;
    }
}

footer {
    .container {
        max-width: 100%;
        margin: auto;
    }
}

@keyframes heartbeat {
    0% {
        transform: scale(0.75);
    }
    20% {
        transform: scale(1.3);
        fill: rgb(255, 117, 117);
    }
    40% {
        transform: scale(0.75);
    }
    60% {
        transform: scale(1);
    }
    80% {
        transform: scale(0.75);
    }
    100% {
        transform: scale(0.75);
    }
}

@keyframes showElement {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

@keyframes flowersMoving {
    0% {
        transform: rotate(0deg);
    }
    50% {
        transform: rotate(15deg);
    }
    100% {
        transform: rotate(0deg);
    }
}

.flowersMove {
    -webkit-animation: flowersMoving ease infinite alternate;
    -moz-animation: flowersMoving ease infinite alternate;
    animation: flowersMoving ease infinite alternate;
    -webkit-animation-duration: 10s, 10s;
    -moz-animation-duration: 10s, 10s;
    animation-duration: 10s, 10s;
}

@keyframes typing {
    from {
        width: 0;
    }
    to {
        width: 100%;
    }
}

@keyframes blink-caret {
    from,
    to {
        border-color: transparent;
    }
    50% {
        border-color: rgba(204, 138, 133, 0.8);
    }
}

@-webkit-keyframes fadeIn {
    from {
        opacity: 0;
    }
    to {
        opacity: 0.5;
    }
}

@-moz-keyframes fadeIn {
    from {
        opacity: 0;
    }
    to {
        opacity: 0.5;
    }
}

@keyframes fadeIn {
    from {
        opacity: 0;
    }
    to {
        opacity: 0.5;
    }
}

.fade-in {
    opacity: 0;
    -webkit-animation: fadeIn ease-in 1;
    -moz-animation: fadeIn ease-in 1;
    animation: fadeIn ease-in 1;
    -webkit-animation-fill-mode: forwards;
    -moz-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
    -webkit-animation-duration: 1s;
    -moz-animation-duration: 1s;
    animation-duration: 1s;
}

header {
    width: 100%;
    min-height: 85vh;
    position: relative;

    .cover {
        background-repeat: no-repeat;
        background-image: url('../assets/images/cover.jpg');
        background-size: cover;
        // background-position: center 70%;
        background-position: 30% 50%;
        width: 100%;
        height: 100%;
        opacity: 1;
        visibility: inherit;
        position: absolute;

        &:after {
            content: '';
            background-color: rgba(255, 246, 247, 0.1);
            width: 100%;
            height: 100%;
            position: absolute;
            left: 0;
            top: 0;
        }
    }

    .cover-flowers-left {
        position: absolute;
        left: -209px;
        bottom: -150px;
        @extend .flowersMove;

        @include MQ(0, $md) {
            left: -185px;
            max-width: 380px;
        }
    }

    .cover-flowers-right {
        position: absolute;
        right: -209px;
        bottom: -207px;
        @extend .flowersMove;

        @include MQ(0, $md) {
            right: -185px;
            max-width: 380px;
        }
    }

    .gradient {
        position: absolute;
        top: 0;
        left: 0;
        height: 20%;
        width: 100%;
        background: rgb(2, 0, 36);
        background: linear-gradient(
            180deg,
            rgba(2, 0, 36, 0.5214286398153012) 0%,
            rgba(255, 190, 255, 0) 97%
        );
        opacity: 0.7;
    }

    .gradient-bottom {
        @extend .gradient;
        bottom: 0;
        top: unset;
        background: linear-gradient(
            0deg,
            rgba(2, 0, 36, 0.521429) 50%,
            rgba(255, 190, 255, 0) 100%
        );
    }

    .rings {
        position: absolute;
        left: 50%;
        top: 10px;
        transform: translate(-50%, 30%);
        fill: #fff;
        // max-width: 65px;

        animation: heartbeat 1.4s infinite;
    }

    .names {
        width: 100%;
        text-align: center;
        position: absolute;
        bottom: 100px;
        bottom: 50px;
        color: #fdc75e;
        text-shadow: -2px 3px 5px #000000;
        font-size: 5rem;
        font-family: 'Great Vibes', cursive;
        opacity: 1;

        @include MQ(0, $md) {
            font-size: 3rem;
            bottom: 80px;
        }
    }
}

//https://codepen.io/lucidx/pen/jLyaQa?editors=0110
#intro {
    display: flex;
    justify-content: center;
    flex-direction: column;
    height: 100vh;
    overflow: hidden;

    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    z-index: 3;

    .top,
    .bottom {
        position: relative;
        z-index: 1;
        height: 50vh;
    }
    .top {
        background: rgba(209, 202, 135, 0.8);
        transition: transform 800ms ease;
    }

    .bottom {
        background: rgba(209, 202, 135, 0.8);
        transition: transform 1200ms ease;
    }

    .left,
    .right {
        position: absolute;
        z-index: 1;
        width: 50vw;
        height: 100vh;
    }
    .left {
        background: rgba(204, 138, 133, 0.8);
        left: 0;
        transition: transform 1800ms ease;
    }

    .right {
        background: rgba(204, 138, 133, 0.8);
        right: 0;
        transition: transform 1000ms ease;
    }

    .up {
        transform: translateY(-100%);
    }

    .down {
        transform: translateY(100%);
    }

    .goLeft {
        transform: translateX(-200%);
    }

    .goRight {
        transform: translateX(200%);
    }
}

//https://desenvolvimentoparaweb.com/css/slanted-shapes-formas-diagonais/
.triangle {
    &:before {
        content: '';
        height: 230px;
        position: absolute;
        transform: skewY(2deg);
        width: 100%;
        background-color: #fff6f7;
        top: -0.3%;
        right: 0;
        z-index: -1;
    }
    &:after {
        content: '';
        width: 100%;
        height: 100%;
        position: absolute;
        background-color: rgba(255, 246, 247, 0.1);
        top: 0;
        left: 0;
        z-index: -1;
    }
}

//https://css-tricks.com/snippets/css/typewriter-effect/
.date {
    margin-top: -10px;
    width: 0;
    text-align: center;
    color: #736049;
    font-size: 3rem;
    font-family: 'Great Vibes', cursive;

    overflow: hidden;
    border-right: 0.15em solid transparent;
    white-space: nowrap;
    animation: typing 8s steps(40, end) 3s forwards,
        blink-caret 0.75s step-end 7;
}

.count {
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 500px;
    position: relative;
    margin-top: 40px;
    margin-bottom: 40px;

    &:after {
        content: '•••';
        position: absolute;
        left: -40%;
        top: 15%;
        color: rgba(118, 101, 80, 0.19);
        font-size: 4rem;
        letter-spacing: 1rem;

        @include MQ(0, $lg) {
            left: -3%;
            top: 80%;
        }
    }

    div {
        background-color: rgba(204, 138, 133, 0.8);
        display: flex;
        flex-direction: column;
        color: #fff;
        border-radius: 6px;
        padding: 10px 20px;
        align-items: center;
        margin: auto;
        width: 100px;
        height: 100px;
        justify-content: center;
        font-size: 3rem;
        font-family: 'Great Vibes', cursive;

        span {
            font-size: 1.2rem;
            line-height: 1.2rem;
            font-family: 'Josefin Slab', serif;
            font-weight: 500;
            color: #fff1f1;
        }

        &.message {
            font-size: 1.8rem;
            width: 80%;
            letter-spacing: 0.1rem;
        }
    }

    @include MQ(0, $md) {
        width: 95%;

        div {
            width: 23.5%;
            height: 90px;
            line-height: 2.5rem;
        }
    }
}

.line {
    width: 100%;
    height: 10px;
    margin: 20px 0;

    &:after {
        display: block;
        content: '';
        border-top: 1px solid #a49689;
        width: 55%;
        left: 45%;
        position: relative;
    }
}

.glass {
    &:after {
        content: '';
        background-color: rgba(255, 246, 247, 0.23);
        width: 100%;
        height: 100%;
        position: absolute;
        left: 0;
        top: 0;
    }
}

.titleWithDecoration {
    font-family: 'Josefin Slab', serif;
    font-weight: 700;
    font-size: 3.5rem;
    display: flex;
    justify-content: center;

    @include MQ(0, $md) {
        font-size: 2rem;
    }

    img {
        max-width: 100px;
        margin: 0px 20px;

        @include MQ(0, $md) {
            max-width: 50px;
        }
    }
}

.backgroundWide {
    &:after {
        content: '';
        width: 1000%;
        position: absolute;
        background: white;
        display: block;
        height: 100%;
        left: -100%;
        z-index: -1;
        top: 0;
    }
}

.passaros {
    max-width: 1440px;
    display: flex;
    align-content: center;
    width: 100%;
    margin: 0 auto;
}

.passaros1 {
    margin-bottom: 10px;
    margin-top: -50px;

    @include MQ(0, $lg) {
        margin-top: -30px;
        margin-left: -10%;
        margin-right: 10%;
        width: 120%;
    }
}

.passaros2 {
    margin-top: -140px;
    margin-bottom: 40px;

    @include MQ(0, $lg) {
        margin-top: -150px;
        margin-bottom: 20px;
    }
}

.passaros3 {
    margin-top: -10px;
    margin-bottom: -80px;
}

.passaros4 {
    margin-top: -80px;
    margin-bottom: -10px;
    background-color: #fff;

    svg {
        max-width: 1000px;
    }

    @include MQ(0, $lg) {
        margin-top: -50px;
        margin-bottom: 0px;
    }
}

.passaros5 {
    margin-top: -90px;
    margin-bottom: -20px;
    background-color: #fff;

    @include MQ(0, $lg) {
        margin-top: -20px;
        margin-bottom: 0px;
    }
}

.timeline {
    position: relative;

    &:before {
        background: #d7a39f;
    }

    p {
        font-family: 'Josefin Slab', serif;
        font-weight: 500;
        font-style: italic;
        line-height: 1.4rem;
        font-size: 1.2rem;
    }

    .vertical-timeline-element-icon {
        top: 50%;
    }

    .vertical-timeline-element-title {
        font-family: 'Great Vibes', cursive;
        font-weight: 500;
        font-size: 2rem;
    }

    .vertical-timeline-element-subtitle {
        font-family: 'Josefin Slab', serif;
        font-weight: 600;
        color: #70a076;
        font-size: 1.3rem;
    }

    .timeline-icon-first {
        .vertical-timeline-element-icon {
            width: 20px;
            height: 20px;
            margin-left: -10px;
            top: -50px;
        }
        .vertical-timeline-element-content {
            height: 0;
            margin: 0;
            padding: 0;
        }
    }

    .timeline-icon-last {
        .vertical-timeline-element-icon {
            width: 20px;
            height: 20px;
            margin-left: -10px;
            top: 133%;
        }
    }

    .vertical-timeline-element-date {
        display: none !important;
    }

    .vertical-timeline-element--left {
        .vertical-timeline-element-content {
            background: transparent !important;
            box-shadow: none !important;

            h3,
            h4,
            p {
                text-align: right;
            }

            span {
                @extend .glass;
                max-width: 300px;
                position: absolute;
                right: -92%;
                top: 20%;
                cursor: pointer;
            }
            img {
                width: 100%;
                border-radius: 5px;
            }
        }
    }

    .vertical-timeline-element--right {
        .vertical-timeline-element-content {
            background: transparent !important;
            box-shadow: none !important;

            h3,
            h4,
            p {
                text-align: left;
            }

            span {
                @extend .glass;
                max-width: 300px;
                position: absolute;
                left: -92%;
                top: 20%;
                cursor: pointer;
            }
            img {
                // @extend .glass;
                width: 100%;
                border-radius: 5px;
            }
        }
    }

    @include MQ(0, 1150) {
        .timeline-icon-first,
        .timeline-icon-last {
            .vertical-timeline-element-icon {
                margin-left: 10px;
            }
        }

        .vertical-timeline-element--right {
            .vertical-timeline-element-content {
                span {
                    position: relative;
                    top: 0;
                    left: 0;
                    display: block;
                    margin: auto;
                    margin-top: 30px;
                }
            }
        }

        .vertical-timeline-element--left {
            .vertical-timeline-element-content {
                h3,
                h4,
                p {
                    text-align: left;
                }

                span {
                    position: relative;
                    top: 0;
                    left: 0;
                    display: block;
                    margin: auto;
                    margin-top: 30px;
                }
            }
        }
    }
}
.fslightbox-toolbar-button:nth-child(1) {
    display: none;
}

.us {
    display: flex;
    justify-content: right;
    position: relative;
    margin-top: 60px;
    margin-bottom: 150px;

    .background-title {
        position: absolute;
        top: 0;
        left: -10%;
        font-size: 11rem;
        font-family: 'Great Vibes', cursive;
        color: rgba(118, 101, 80, 0.19);
    }
    .box {
        max-width: 700px;
        position: relative;

        .background-square {
            background-color: #f4ede7;
            top: 25%;
            position: absolute;
            left: -20%;
            height: 85%;
            width: 45%;
            z-index: -1;

            span {
                font-family: 'Great Vibes', cursive;
                font-size: 4rem;
                color: #cec7c4;
                padding-top: 50px;
                padding-left: 20px;
                writing-mode: vertical-rl;
                text-orientation: mixed;
                height: 100%;
                width: 100%;
                display: flex;
                align-items: end;
            }
        }

        .images {
            position: relative;

            .flower {
                position: absolute;
                top: -80px;
                right: -20px;
                z-index: 1;
            }
            .us-gallery {
                position: relative;
                @extend .glass;

                img {
                    width: 100%;
                    border-radius: 3px;
                }
            }
        }
        .title {
            margin-top: 30px;
            margin-bottom: 20px;
            font-family: 'Josefin Slab', serif;
            font-weight: 700;
        }
        .text {
            font-family: 'Josefin Slab', serif;
            font-weight: 500;
            font-style: italic;
            line-height: 1.3rem;
            width: 90%;
            text-align: justify;
        }
    }

    @include MQ(0, $lg) {
        padding-top: 120px;

        .background-title {
            font-size: 8rem;
        }

        .box {
            max-width: 80%;

            .background-square {
                left: -30%;

                span {
                    font-size: 3rem;
                    padding-top: 80px;
                    padding-left: 10px;
                }
            }

            .images {
                .flower {
                    max-width: 130px;
                    top: -42px;
                }
            }
        }
    }
}

.about {
    width: 80%;

    .bride {
        position: relative;
        display: flex;

        div.image {
            position: relative;
            @extend .glass;
            width: 200px;
            margin: 5px;
            text-align: center;
            transform-origin: center;

            img {
                width: 100%;
            }
        }

        div.text {
            padding: 5px;
            padding-left: 20px;
            display: flex;
            flex-direction: column;
            flex: 1;
        }

        span {
            font-size: 3rem;
            color: #f07e86;
            font-family: 'Parisienne', cursive;
        }

        p {
            padding-top: 10px;
            font-family: 'Josefin Slab', serif;
            font-weight: 500;
            font-style: italic;
            line-height: 1.3rem;
            text-align: justify;
        }

        div.links {
            padding-top: 10px;

            a {
                margin-right: 20px;
                cursor: pointer;

                &:hover {
                    opacity: 0.7;
                }
            }
        }
    }

    .groom {
        flex-direction: row-reverse;
        text-align: right;

        div.text {
            padding-left: 0;
            padding-right: 20px;
        }
    }

    @keyframes effectHeart1 {
        0% {
            top: -50px;
            left: -30px;
        }
        50% {
            top: -70px;
            left: -10px;
        }
        100% {
            top: -50px;
            left: -30px;
        }
    }

    .animate-heart1 {
        -webkit-animation: effectHeart1 ease infinite alternate;
        -moz-animation: effectHeart1 ease infinite alternate;
        animation: effectHeart1 ease infinite alternate;
        -webkit-animation-duration: 5s;
        -moz-animation-duration: 5s;
        animation-duration: 5s;
    }

    @keyframes effectHeart2 {
        0% {
            top: -10px;
            left: -10px;
        }
        50% {
            top: -20px;
            left: -20px;
        }
        100% {
            top: -10px;
            left: -10px;
        }
    }

    .animate-heart2 {
        -webkit-animation: effectHeart2 ease infinite alternate;
        -moz-animation: effectHeart2 ease infinite alternate;
        animation: effectHeart2 ease infinite alternate;
        -webkit-animation-duration: 5s;
        -moz-animation-duration: 5s;
        animation-duration: 5s;
    }

    div.hearts {
        position: relative;
        @extend .glass;
        width: 100%;
        text-align: center;

        img {
            position: relative;
        }

        img.heart1 {
            top: -50px;
            left: -30px;
            @extend .animate-heart1;
        }
        img.heart2 {
            top: -10px;
            left: -10px;
            @extend .animate-heart2;
        }
    }

    @include MQ(0, $md) {
        width: 90%;

        .bride {
            flex-direction: column;

            div.image {
                align-self: center;
            }
        }

        .groom {
            flex-direction: column;
        }

        div.hearts {
            @include MQ(0, $lg) {
                text-align: right;
                right: 20%;
                margin-top: 40px;
            }

            @include MQ(0, $md) {
                text-align: right;
                right: 40%;
            }
        }
    }

    @include MQ(0, $lg) {
        div.hearts {
            text-align: right;
            right: 20%;
            margin-top: 40px;
        }
    }
}

/*
.infos {
    width: 100%;
    display: flex;
    justify-content: left;
    position: relative;
    margin-top: 60px;
    margin-bottom: 150px;

    .background-title {
        position: absolute;
        top: 0;
        left: -7.5%;
        font-size: 11rem;
        font-family: 'Great Vibes', cursive;
        color: rgba(118, 101, 80, 0.19);

        @include MQ(0, $lg) {
            font-size: 6rem;
        }
    }
    .box {
        max-width: 700px;
        position: relative;
        margin-top: 250px;

        .background-square {
            background-color: #f4ede7;
            top: 10%;
            position: absolute;
            right: -60%;
            height: 80%;
            width: 80%;
            z-index: 2;
            padding: 30px;

            span {
                font-family: 'Great Vibes', cursive;
                font-size: 2rem;
                color: #cec7c4;
                padding-top: 50px;
            }
            .title {
                margin-top: 30px;
                margin-bottom: 20px;
                font-family: 'Josefin Slab', serif;
                font-weight: 500;
                font-size: 2rem;
                line-height: 2.5rem;
            }
            .text {
                font-family: 'Josefin Slab', serif;
                font-weight: 500;
                font-style: italic;
                line-height: 1.5rem;
                font-size: 1.1rem;
                width: 90%;
            }
        }

        .images {
            position: relative;

            .flower {
                position: absolute;
                bottom: -21%;
                left: 0;
                z-index: 1;
                max-width: 80%;
            }
            .us-gallery {
                position: relative;
                @extend .glass;

                img {
                    width: 100%;
                }
            }
        }
    }

    @include MQ(0, $lg) {
        margin-bottom: 170px;

        .box {
            max-width: 60%;
            margin-top: 150px;

            .background-square {
                height: auto;
                right: -65%;
                width: 110%;

                span {
                    font-size: 1.7rem;
                    padding-top: 0;
                }

                .text {
                    line-height: 1rem;
                    font-size: 1rem;
                }
            }
        }
    }

    @include MQ(0, $md) {
        .box {
            .background-square {
                .title {
                    font-size: 1rem;
                    line-height: 1.5rem;
                    margin-top: 5px;
                    margin-bottom: 5px;
                }
            }
        }
    }

    @include MQ($md, $lg) {
        .box {
            .background-square {
                .title {
                    font-size: 1.5rem;
                    line-height: 2rem;
                    margin-top: 10px;
                    margin-bottom: 10px;
                }
            }
        }
    }
}
*/

.celebration {
    display: flex;
    justify-content: center;
    flex-direction: column;
    position: relative;
    margin-top: 80px;

    .title {
        @extend .titleWithDecoration;
    }

    .content {
        display: flex;
        flex-direction: row;
        margin-top: 20px;
    }

    .left {
        display: flex;
        flex-direction: column;
        width: 60%;

        .item {
            margin-bottom: 40px;

            img {
                width: 55px;
                padding-right: 20px;
            }

            h1 {
                margin-top: 10px;
                margin-bottom: 10px;
                display: flex;
                align-items: center;
                font-family: 'Libre Franklin', sans-serif;
                font-weight: 500;
                color: #f07e86;
            }

            .description {
                padding-left: 60px;
                font-size: 1.2rem;
                font-family: 'Josefin Slab', serif;
                font-weight: 500;
                // color: #fff1f1;
            }
        }

        .photo {
            position: relative;
            display: flex;

            .background {
                position: relative;
                right: -50px;
                z-index: -1;
                // width: 39%;
                // max-height: 533px;
                align-self: center;
                margin-top: -15%;
            }

            div {
                max-width: 340px;
                left: -5%;
                position: relative;

                img:nth-child(2) {
                    top: -45px;
                    position: relative;
                    right: -17px;
                }

                img:nth-child(3) {
                    top: -75px;
                    position: relative;
                    right: -1px;
                }

                img:nth-child(4) {
                    top: -109px;
                    position: relative;
                    right: -26px;
                }

                img {
                    width: 100%;
                    // &:last-child {
                    //     overflow: hidden;
                    //     @extend .glass;
                    //     width: 50%;
                    // }
                }
            }
        }
    }

    .right {
        display: flex;
        flex-direction: column;
        align-items: end;
        width: 40%;

        figure {
            max-width: 80%;
            top: 11%;
            position: relative;
            margin-right: 30px;
            @extend .glass;

            // margin-right: 0;
            border: 6px solid #ffffff;
            box-shadow: 0 3px 6px rgba(0, 0, 0, 0.1),
                0 3px 6px rgba(0, 0, 0, 0.13);

            img {
                width: 100%;
                border-radius: 5px;
            }

            figcaption {
                font-family: 'Josefin Slab', serif;
                font-weight: 500;
                font-style: italic;
                font-size: 1.6rem;
                padding: 10px 0;
                text-align: center;
                background: #fff;
            }
        }

        .howToArrive {
            position: relative;
            top: 13%;
            width: 100%;

            h2 {
                display: flex;
                align-items: center;
                font-family: 'Libre Franklin', sans-serif;
                font-weight: 500;
                font-size: 1.5rem;
                color: #f07e86;

                svg {
                    fill: #f07e86;
                    margin-right: 20px;
                }
            }

            .btnDefault {
                border: 1px solid #f17677;
                display: flex;
                flex-direction: row;
                margin-bottom: 30px;
                max-width: 500px;
                cursor: pointer;
                transition: background 0.2s;
                text-decoration: none;

                &:hover {
                    background: #ffe2ec;
                }

                svg {
                    fill: #fff;
                    margin: 10px 30px;
                    background: #f17677;
                    border-radius: 50%;
                    padding: 7px;
                }

                & > div {
                    flex: 1;
                    display: flex;
                    align-items: center;
                    justify-content: center;

                    &:before {
                        content: '';
                        height: 40px;
                        background: #f17677;
                        width: 1px;
                        left: 0;
                    }

                    div {
                        flex: 1;
                        display: flex;
                        align-items: center;
                        justify-content: center;

                        color: #f17677;

                        span {
                            margin-left: 5px;
                            color: #a4595a;
                            text-decoration: underline;
                        }
                    }
                }
            }
        }
    }

    .originDestiny {
        position: absolute;
        bottom: -270px;
        height: 350px;
        right: 0;
        z-index: 3;
    }

    @include MQ(0, $lg) {
        .content {
            flex-direction: column;
        }

        .left {
            width: 100%;

            .photo {
                // text-align: right;
                // // right: -10%;
                // right: 0;
                // flex-direction: row-reverse;

                .background {
                    // position: absolute;
                    // top: 0;
                    // right: 45%;
                    // width: 60%;
                    // left: 0;

                    right: 0;
                    left: 5%;
                    width: 40%;
                }

                div {
                    // left: -2%;
                    width: 60%;
                }
            }
        }

        .right {
            width: 100%;
            align-items: center;

            figure {
                // right: -10%;
                max-width: 80%;
                margin-right: 0;

                figcaption {
                    font-size: 1.2rem;
                    text-align: center;
                }
            }

            .howToArrive {
                .btnDefault {
                    max-width: 100%;
                }
            }
        }

        .originDestiny {
            height: 250px;
            right: -3%;
        }
    }
}

.party {
    width: 100%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    margin-top: 170px;
    position: relative;
    background: #fff;

    @extend .backgroundWide;

    .title {
        @extend .titleWithDecoration;
        z-index: 2;
    }

    .buffet {
        position: relative;
        margin-top: 20px;
        @extend .glass;
        &:after {
            background-color: rgba(255, 246, 247, 0.09);
        }

        img {
            &:first-child {
                width: 100%;
            }
        }
        .logo {
            position: relative;
            top: -39px;
            max-width: 590px;
            margin-left: 5%;
            z-index: 1;
        }

        .teste {
            position: absolute;
            top: 0;
            width: 100%;
            z-index: 1;

            &:before {
                content: '';
                height: 200px;
                position: absolute;
                transform: skewY(5deg);
                width: 100%;
                background-color: #fff6f7;
                top: -140px;
            }
        }
    }

    .content {
        display: flex;
        flex-direction: row;
        justify-content: center;
        width: 90%;
        align-self: center;
    }

    .left {
        display: flex;
        flex-direction: column;
        width: 60%;

        .item {
            margin-bottom: 40px;

            h1 {
                margin-top: 10px;
                margin-bottom: 10px;
                display: flex;
                align-items: center;
                font-family: 'Libre Franklin', sans-serif;
                font-weight: 400;
                color: #4c3336;
            }

            .description {
                display: flex;
                align-items: center;
                font-size: 1.2rem;
                font-family: 'Libre Franklin', sans-serif;
                font-style: italic;
                font-weight: 300;
                color: #4c3336;

                &:before {
                    content: '';
                    height: 1px;
                    background: #2c0e0f;
                    width: 70px;
                    margin-right: 30px;
                }
            }
        }

        .howToArrive {
            width: 100%;

            h2 {
                display: flex;
                align-items: center;
                font-family: 'Libre Franklin', sans-serif;
                font-weight: 500;
                font-size: 1.5rem;
                color: #4c3336;

                svg {
                    fill: #4c3336;
                    margin-right: 20px;
                }

                &:before {
                    content: '';
                    height: 1px;
                    background: #2c0e0f;
                    width: 70px;
                    margin-right: 30px;
                }
            }

            .btnDefault {
                border: 1px solid #4c3336;
                display: flex;
                flex-direction: row;
                margin-bottom: 30px;
                max-width: 500px;
                cursor: pointer;
                text-decoration: none;
                transition: background 0.2s;

                &:hover {
                    background: rgba(29, 10, 12, 0.08);
                }

                svg {
                    fill: #4c3336;
                    margin: 10px 30px;
                    background: transparent;
                    border-radius: 50%;
                    padding: 2px;
                }

                & > div {
                    flex: 1;
                    display: flex;
                    align-items: center;
                    justify-content: center;

                    &:before {
                        content: '';
                        height: 40px;
                        background: #4c3336;
                        width: 1px;
                        left: 0;
                    }

                    div {
                        flex: 1;
                        display: flex;
                        align-items: center;
                        justify-content: center;

                        color: #4c3336;

                        span {
                            margin-left: 5px;
                            color: #1d0a0c;
                            text-decoration: underline;
                        }
                    }
                }
            }
        }
    }

    .right {
        display: flex;
        flex-direction: column;
        align-items: end;
        width: 40%;
        left: 10%;
        position: relative;

        div {
            background: #f4efec;
            border-radius: 50%;
            width: 800px;
            height: 800px;
            right: -70%;
            position: relative;
        }

        img {
            position: absolute;
            width: 230px;
            right: 30%;
            top: 10%;
        }
    }

    @include MQ(0, $lg) {
        .buffet {
            .logo {
                max-width: 90%;
                top: -25px;
            }
        }
        .left {
            z-index: 1;

            .item {
                &:nth-child(1),
                &:nth-child(2) {
                    width: 165%;
                }
            }

            .howToArrive {
                width: 165%;
            }
        }
        .right {
            z-index: 0;
            margin-top: 50%;

            div {
                width: 600px;
                height: 600px;
            }

            img {
                right: 15%;
                width: 140px;
            }
        }
    }

    @include MQ(0, $md) {
        .right {
            div {
                right: -190%;
            }
        }
    }
}

.map {
    width: 100%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    padding-top: 70px;
    position: relative;
    background: #fff;

    @extend .backgroundWide;

    .title {
        @extend .titleWithDecoration;
        z-index: 2;
    }

    .googleMap {
        margin-top: 30px;
    }

    .options {
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        margin-top: 30px;

        .btnDefault {
            border: 1px solid #4c3336;
            display: flex;
            flex-direction: row;
            margin-bottom: 30px;
            max-width: 500px;
            cursor: pointer;
            text-decoration: none;
            transition: background 0.2s;

            &:hover {
                background: rgba(29, 10, 12, 0.08);
            }

            svg {
                fill: #4c3336;
                margin: 10px 30px;
                background: transparent;
                border-radius: 50%;
                padding: 2px;
            }

            & > div {
                flex: 1;
                display: flex;
                align-items: center;
                justify-content: center;

                &:before {
                    content: '';
                    height: 40px;
                    background: #4c3336;
                    width: 1px;
                    left: 0;
                }

                div {
                    flex: 1;
                    display: flex;
                    align-items: center;
                    justify-content: center;

                    color: #4c3336;
                    padding: 0 20px;
                }
            }
        }
    }

    @include MQ(0, $lg) {
        .options {
            flex-direction: column;
            align-self: center;
            width: 90%;

            .btnDefault {
                max-width: 100%;
            }
        }
    }
}

.gallery {
    width: 100%;
    padding-top: 50px;
    padding-bottom: 50px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
    background: #fff;

    @extend .backgroundWide;

    .content {
        width: 80%;
        background: #fff;
    }

    .title {
        @extend .titleWithDecoration;
        padding-bottom: 50px;
    }

    .react-photo-gallery--gallery {
        div {
            @extend .glass;
            &:hover {
                img {
                    &:hover {
                        transition: border 0.2s;
                        border: 10px solid #f07e86;
                    }
                }
                &:after {
                    display: none;
                }
            }
        }
    }

    @include MQ(0, $lg) {
        padding-top: 10px;

        .content {
            width: 100%;
        }
    }

    .manualGallery {
        display: flex;
        flex-direction: row;

        div {
            flex: 1;

            img {
                width: 100%;
                padding: 3px 5px;
                transition: opacity 0.2s;
                opacity: 0.9;

                &:hover {
                    // transition: border 0.2s;
                    // border: 10px solid #f07e86;
                    transition: opacity 0.2s;
                    opacity: 1;
                    cursor: pointer;
                }
            }
        }
    }
}
.react-images__footer__count,
.react-images__footer__count--isModal {
    display: none !important;
}
nav.react-images__navigation button {
    background: #f07e86;

    &:hover {
        background: #f07e86;
    }
}

.flash {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #165d78;
    display: none;
    background-image: url('../assets/images/camera.png');
    background-repeat: no-repeat;
    background-position: center;
}

.socialMedia {
    width: 100%;
    padding-top: 50px;
    position: relative;
    display: flex;
    justify-content: center;
    background: #fff;
    padding-bottom: 370px;

    @extend .backgroundWide;

    .wrapper {
        width: 80%;
        display: flex;
        justify-content: center;
        flex-direction: column;
    }

    .left {
        display: flex;
        flex-direction: column;
        width: 100%;

        h1 {
            margin: 0;
            display: flex;
            flex-direction: column;

            span {
                font-family: 'Libre Franklin', sans-serif;
                font-weight: 700;
                font-size: 3em;
                color: #000;
            }
        }

        h2 {
            margin: 0;
            display: flex;
            flex-direction: column;

            span {
                font-family: 'Libre Franklin', sans-serif;
                font-weight: 300;
                font-size: 2.9em;
                color: #000;
            }
        }

        img {
            position: absolute;
            margin-left: 28%;
        }
    }

    .content {
        display: flex;
        flex-direction: column;
        justify-content: center;
        width: 90%;
        align-self: center;
        margin-top: 50px;
        position: relative;

        .hashtag {
            margin: 0;
            display: flex;
            flex-direction: row;
            justify-content: center;
            z-index: 1;

            span {
                font-family: 'Libre Franklin', sans-serif;
                font-weight: 700;
                font-size: 3em;
                color: #000;

                &:first-child {
                    color: #f17677;
                }

                &:nth-child(2) {
                    color: #bd5758;
                }

                &:nth-child(3) {
                    color: #603030;
                }
            }

            button {
                background: #e3e7eb;
                color: #416299;
                border-radius: 6px;
                padding: 15px 10px;
                line-height: 0;
                height: 10px;
                align-self: center;
                margin-left: 20px;
                border: 1px solid #9faebd;

                &:hover {
                    background: #dae0e7;
                }
            }
        }

        .text {
            text-align: center;
            font-family: 'Libre Franklin', sans-serif;
            font-weight: 400;
            font-size: 2em;
            margin-top: 40px;
        }

        .background {
            display: flex;
            flex-direction: column;
            align-items: end;
            width: 40%;
            right: -20%;
            position: absolute;
            top: -115%;

            div {
                position: relative;
                width: 365px;
                display: flex;
                align-items: center;

                &:after {
                    content: '';
                    height: 1px;
                    background: #bd5758;
                    width: 110%;
                    margin-right: 30px;
                    left: 10px;
                    position: absolute;
                }
            }

            & > img {
                position: absolute;
                width: 400px;
                right: -30%;
                top: 50px;
            }
        }
    }

    @include MQ(0, $md) {
        .wrapper {
            .left {
                img {
                    margin-left: 45%;
                }
            }
        }
    }

    @include MQ($md, $lg) {
        .wrapper {
            .left {
                img {
                    margin-left: 30%;
                }
            }
        }
    }

    @include MQ(0, $lg) {
        font-size: 8px;

        .wrapper {
            width: 95%;

            .left {
                img {
                    // margin-left: 50%;
                }
            }

            .content {
                .hashtag {
                    font-size: 9.5px;

                    button {
                        position: absolute;
                        top: 53px;
                    }
                }

                .text {
                    font-size: 1.4rem;
                    margin-top: 70px;
                }

                .background {
                    top: -25%;

                    div {
                        width: 265px;
                    }

                    & > img {
                        width: 300px;
                    }
                }
            }
        }
    }
}

.whatWeLike {
    width: 100%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    position: relative;
    background-color: #f9f9f9;
    align-items: center;
    padding-bottom: 50px;

    .background {
        position: relative;
        width: 100%;

        &:before {
            content: '';
            height: 200px;
            position: absolute;
            transform: skewY(-5deg);
            width: 100%;
            background-color: #f9f9f9;
            top: -55px;
            right: 0;
        }
    }

    .background-title {
        position: absolute;
        top: 5%;
        left: -3%;
        font-size: 11rem;
        font-family: 'Great Vibes', cursive;
        color: rgba(118, 101, 80, 0.19);

        @include MQ(0, $md) {
            font-size: 3.7rem;
            top: -1%;
        }

        @include MQ($md, $lg) {
            font-size: 6rem;
        }

        @include MQ($lg, $xl) {
            font-size: 10rem;
        }
    }

    .text {
        padding-top: 300px;
        text-align: center;
        font-family: 'Josefin Slab', serif;
        font-weight: 500;
        font-style: italic;
        font-size: 1.5rem;
        width: 70%;
        z-index: 1;

        @include MQ($md, $lg) {
            padding-top: 200px;
            width: 90%;
        }

        @include MQ(0, $md) {
            padding-top: 100px;
            width: 90%;
        }
    }

    .stores {
        padding-top: 70px;
        padding-bottom: 50px;
        display: flex;
        align-content: center;
        align-items: center;
        width: 80%;
        justify-content: space-around;

        a {
            background: #fff;
            box-shadow: 0 3px 6px rgba(0, 0, 0, 0.05),
                0 3px 6px rgba(0, 0, 0, 0.05);
            transition: background 0.2s;

            &:hover {
                background: rgba(240, 126, 134, 0.25);
            }
        }

        & > img {
            position: relative;
            top: -30px;
        }

        @include MQ(0, $lg) {
            width: 90%;

            a {
                width: 30%;

                img {
                    width: 100%;
                }
            }

            & > img {
                width: 30%;
                top: 0;
            }
        }
    }

    .thankyou {
        text-align: center;
        font-family: 'Parisienne', cursive;
        font-size: 3rem;
        color: #f07e86;
    }
}

//https://pixelcog.github.io/parallax.js/
// .ldPhoto {
//     width: 100%;
//     display: flex;
//     justify-content: center;
//     align-items: center;
//     padding-top: 80px;

//     min-height: 500px;
//     background: transparent;
// }
.ldPhoto {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 80px;

    min-height: 500px;
    background: transparent;

    div {
        position: fixed;
        left: 0;
        top: 0;
        width: 100%;
        height: 100vh;
        z-index: -3;

        img {
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            object-fit: cover;
            pointer-events: none;
            display: none;

            max-width: 1438px;
            margin: auto;
            right: 0;
        }
    }
}

.contact {
    width: 100%;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-top: 200px;
    margin-top: 50px;
    background: rgb(255, 255, 255);
    z-index: 1;
    // background: linear-gradient(
    //     0deg,
    //     rgba(255, 255, 255, 1) 0%,
    //     rgba(255, 255, 255, 0) 90%
    // );
    // background: linear-gradient(
    //     0deg,
    //     rgba(255, 255, 255, 1) 0%,
    //     rgba(255, 255, 255, 0) 100%
    // );

    .background-title {
        position: absolute;
        top: 0;
        left: -2%;
        font-size: 11rem;
        font-family: 'Great Vibes', cursive;
        color: rgba(118, 101, 80, 0.19);
        // color: #fff;

        @include MQ(0, $lg) {
            font-size: 8rem;
        }
    }

    .box {
        background: #fff;
        box-shadow: 0 3px 6px rgba(0, 0, 0, 0.05), 0 3px 6px rgba(0, 0, 0, 0.05);
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;
        width: 80%;
        max-width: 600px;
        padding: 5%;
        border-radius: 10px;

        .title {
            font-size: 4rem;
            font-family: 'Great Vibes', cursive;
        }
    }

    input:focus,
    textarea:focus,
    select:focus {
        outline: none;
    }

    input,
    textarea {
        font-family: 'Josefin Slab', serif;
        font-weight: 500;
        font-style: italic;
        line-height: 2rem;
        font-size: 1.3rem;
    }

    button {
        font-family: 'Libre Franklin', serif;
        font-weight: 300;
        font-size: 1.3rem;
    }

    form {
        margin-top: 20px;
        width: 100%;
        display: flex;
        flex-direction: column;
        margin-left: auto;
        margin-right: auto;

        @include MQ(0, $lg) {
            width: 95%;
        }

        .field {
            margin-bottom: 40px;
            display: flex;
            flex-direction: row;

            @include MQ(0, $lg) {
                flex-direction: column;

                label {
                    padding: 0;
                    justify-content: flex-start !important;
                    margin-bottom: 10px;
                }
            }

            label {
                width: 20%;
                padding-right: 20px;
                align-items: center;
                display: flex;
                justify-content: flex-end;
            }

            & > div {
                width: 100%;
            }
        }
    }

    .wrapper {
        width: 60%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        position: relative;
        margin: 50px 0;

        hr {
            border: 0;
            height: 1px;
            width: 100%;
            background: #3f3f3f;
        }

        .names {
            font-size: 0.9rem;
            font-family: 'Libre Franklin', sans-serif;
            font-weight: 500;
            position: absolute;
            background: #fff;
            padding: 10px 20px;
            color: #3f3f3f;
        }

        .or {
            font-size: 0.9rem;
            font-family: 'Libre Franklin', sans-serif;
            font-weight: 500;
            position: absolute;
            padding: 10px 20px;
            color: #3f3f3f;
            position: relative;
            display: flex;
            justify-content: center;
            align-items: center;
            align-content: center;

            &:before {
                content: '';
                border: 0;
                height: 1px;
                background: #3f3f3f;
                position: absolute;
                width: 20vw;
                transform: translate(-100%);
                left: 0;
            }

            &:after {
                content: '';
                border: 0;
                height: 1px;
                background: #3f3f3f;
                position: absolute;
                width: 20vw;
                transform: translate(100%);
                right: 0;
            }
        }
    }

    .email {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        align-content: center;
        color: #3f3f3f;
        font-size: 1.1rem;
        font-family: 'Libre Franklin', sans-serif;
        font-weight: 500;
        text-decoration: none;
        padding: 5px 10px;

        &:hover {
            background: rgba(241, 118, 119, 0.11);
            border-radius: 3px;
        }

        svg {
            margin-right: 10px;
            font-size: 1.5rem;
        }

        span {
            color: #7f6e53;
            font-size: 1.1rem;
            font-family: 'Libre Franklin', sans-serif;
            font-weight: 500;
        }
    }
}

#btnPlay {
    position: fixed;
    width: 50px;
    height: 50px;
    // color: #f17677;
    color: #d5d5d5;
    z-index: 100;
    border-radius: 50%;
    left: 10px;
    top: 10px;
    outline: none;
    background: rgba(214, 159, 155, 0.8);
    box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.4);
    opacity: 0.8;
    cursor: pointer;
    transition: all 0.4s ease-out;
    font-size: 23px;
    display: flex;
    align-items: center;
    justify-content: center;
    opacity: 0.8;

    &:hover {
        opacity: 0.8;
        color: #555;
        box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19),
            0 6px 6px rgba(0, 0, 0, 0.23);
    }

    $max: 30px;

    .equalizer {
        height: $max;
        width: $max;
        transform: rotate(180deg);
    }

    .bar {
        fill: #f17677;
        width: 18px;
        animation: equalize 8s 0s infinite;
    }

    .bar:nth-child(1) {
        animation-delay: -1.9s;
    }

    .bar:nth-child(2) {
        animation-delay: -2s;
    }

    .bar:nth-child(3) {
        animation-delay: -2.3s;
    }

    .bar:nth-child(4) {
        animation-delay: -2.4s;
    }

    .bar:nth-child(5) {
        animation-delay: -2.1s;
    }

    @keyframes equalize {
        0% {
            height: 60px;
        }
        4% {
            height: 50px;
        }
        8% {
            height: 40px;
        }
        12% {
            height: 30px;
        }
        16% {
            height: 20px;
        }
        20% {
            height: 30px;
        }
        24% {
            height: 40px;
        }
        28% {
            height: 10px;
        }
        32% {
            height: 40px;
        }
        36% {
            height: 60px;
        }
        40% {
            height: 20px;
        }
        44% {
            height: 40px;
        }
        48% {
            height: 70px;
        }
        52% {
            height: 30px;
        }
        56% {
            height: 10px;
        }
        60% {
            height: 30px;
        }
        64% {
            height: 50px;
        }
        68% {
            height: 60px;
        }
        72% {
            height: 70px;
        }
        76% {
            height: 80px;
        }
        80% {
            height: 70px;
        }
        84% {
            height: 60px;
        }
        88% {
            height: 50px;
        }
        92% {
            height: 60px;
        }
        96% {
            height: 70px;
        }
        100% {
            height: 80px;
        }
    }
}

footer {
    width: 100%;
    color: #d7d8da;
    position: relative;
    padding-top: 70px;
    background: #fff;
    z-index: 1;

    .container {
        display: flex;
        flex-direction: column;

        .wrapper {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            position: relative;

            hr {
                border: 0;
                height: 1px;
                width: 100%;
                background: #a49689;
            }

            .letters {
                font-size: 9rem;
                font-family: 'Josefin Slab', serif;
                font-weight: 400;
                position: absolute;
                background: #fff;
                transform: translateY(10px);
                color: #3f3f3f;
            }

            .names {
                font-size: 1.5rem;
                font-family: 'Josefin Slab', serif;
                font-weight: 500;
                position: absolute;
                background: #fff;
                padding: 10px 20px;
                color: #3f3f3f;
            }
        }
    }
    .credits {
        text-align: center;
        font-size: 0.85rem;
        padding-bottom: 10px;
        padding-top: 100px;
        font-family: 'Libre Franklin', sans-serif;
        font-weight: 400;
        font-style: italic;
        color: #878787;
        padding-bottom: 30px;

        a {
            color: #575757;
            text-decoration: none;

            &:hover {
                text-decoration: underline;
                background: #ffe7d6;
            }
        }

        span {
            display: block;
            line-height: initial;
        }
    }
}
